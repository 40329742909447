import React from "react";
import Header from "./components/Header/Header";
import LoadingModal from "./components/LoadingModal/LoadingModal";
import Routing from "./Pages/Routing";
import { ScrollToTop } from "./Core/utils";
import Footer from "./components/Footer/Footer";
import ContactUsModal from "./components/ContactUs/ContactUsModal";
import AnimatedCursor from "react-animated-cursor";
import Whatsapp from "./components/Whatsapp/Whatsapp";

function App() {
  return (
    <div className="">
      <Header />
      <Routing />
      <Footer />
      <>
        <Whatsapp />
        <LoadingModal />
        <ContactUsModal />
        <ScrollToTop />
        <AnimatedCursor
          showSystemCursor
          outerStyle={{
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderRadius: "100%",
            backgroundClip: "padding-box" /* bg-clip-padding */,
            backdropFilter: "blur(2px)" /* backdrop-filter backdrop-blur-sm */,
            backgroundOpacity: "0.5" /* bg-opacity-10 */,
            border: "1px solid #FFF" /* border border-gray-100 */,
            // mixBlendMode:"color"
            cursor: "pointer",
          }}
          innerSize={8}
          outerSize={8}
          color="#fff"
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={10}
          clickables={[
            "a",
            "h1",
            "h2",
            "img",
            // 'input[type="text"]',
            // 'input[type="email"]',
            // 'input[type="number"]',
            // 'input[type="submit"]',
            // 'input[type="image"]',
            // "textarea",
            "label[for]",
            "select",
            "button",
            ".link",
          ]}
        />
      </>
    </div>
  );
}

export default App;
