import { useContactUsContext } from "src/Context/ContactUsContext";
import React from "react";
import { Dialog, DialogContent } from "../ui/dialog";
import ContactUs from "./ContactUs";
import { Button } from "../ui/button";
import { Cross1Icon } from "@radix-ui/react-icons";

const ContactUsModal = () => {
  const { openModal, closeModal, open } = useContactUsContext();
  console.log("🚀 ~ ContactUsModal ~ open:", open);
  return (
    <Dialog open={open}>
      <DialogContent
        hideDefaultClose
        className="h-full lg:h-full min-w-screen max-w-screen w-full border-0 border-gray-900 rounded-lg lg:rounded-sm"
      >
        <div className="h-full w-full overflow-y-scroll lg:overflow-y-hidden">
          <ContactUs />
        </div>
        <Button
          onClick={closeModal}
          className="border absolute top-2 right-2 p-2 cursor-pointer"
          variant="outline"
        >
          <Cross1Icon />
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ContactUsModal;
