import React, { createContext, useContext, useEffect, useState } from "react";

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    let timer;
    setLoading(true);
    for (let i = 10; i <= 100; i = i + 10) {
      timer = setTimeout(() => {
        setProgress(i);
      }, i * 50);
    }
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setOpen(false);
      setLoading(false);
    }
  }, [progress]);

  const value = {
    loading,
    open,
    setOpen,
    progress,
    setProgress,
  };
  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
};

// Custom hook to consume the loading context
export const useLoadingContext = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }
  return context;
};
