import React from "react";
import { servicesData } from "../../Common/data";

const Services = () => {
  return (
    <section className="pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-12 max-w-[510px] text-center lg:mb-20">
              {/* <span className="mb-2 block text-lg font-semibold text-primary"> */}
              <h1 className="font-poppins scroll-m-20 text-5xl lg:text-6xl font-extrabold tracking-tight w-full">
                Our Services
              </h1>
              {/* </span> */}
              <h2 className="mb-3 mt-3 font-bold text-2xl md:text-[30px]">
                What We Offer
              </h2>
              <p className="text-base text-body-color dark:text-dark-6">
                At F5 Innovations, we specialize in crafting exceptional digital
                experiences tailored to your unique needs. With our team of
                talented developers, designers, and strategists, we're dedicated
                to bringing your vision to life and exceeding your expectations.
              </p>
            </div>
          </div>
        </div>

        <div className="-mx-4 flex flex-wrap">
          {servicesData?.map((element, index) => {
            return (
              <ServiceCard
                title={element.title}
                details={element.details}
                icon={element.icon}
                index={index}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services;

const ServiceCard = ({ icon, title, details, index }) => {
  return (
    <>
      {icon && title && details ? (
        <div className="w-full px-4 md:w-1/2 lg:w-1/3 border rounded-3xl">
          <div className="mb-9 rounded-[20px] p-10 shadow-2 hover:shadow-lg  md:px-7 xl:px-10">
            <div className="mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary">
              {icon}
            </div>
            <h4 className="mb-[14px] text-2xl font-semibold text-dark dark:text-white">
              {title}
            </h4>
            <p className="text-body-color dark:text-dark-6">{details}</p>
          </div>
        </div>
      ) : (
        <div className={`w-full px-4 md:w-1/2 lg:w-1/3 rounded-3xl hidden lg:flex`}></div>
      )}
    </>
  );
};
