import React, { useRef } from "react";
import { Button } from "../ui/button";
import { ArrowRight } from "lucide-react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/src/ScrollTrigger";
import Meteors from "../Meteors/Meteors";
import { useContactUsContext } from "src/Context/ContactUsContext";

gsap.registerPlugin(ScrollTrigger);

const HeroSection = () => {
  const container = useRef();
  const { openModal } = useContactUsContext();
  useGSAP(
    () => {
      gsap.to("[data-speed]", {
        y: (i, el) =>
          (1 - parseFloat(el.getAttribute("data-speed"))) *
          ScrollTrigger.maxScroll(window),
        ease: "bounce.none",
        scrollTrigger: {
          start: 0,
          end: "max",
          invalidateOnRefresh: true,
          scrub: true,
        },
        height: 40,
      });
    },
    { scope: container }
  );

  return (
    <div className="p-4 lg:p-10">
      <div
        className="w-[100] p-4 lg:p-10 relative flex h-full w-full items-center justify-center overflow-hidden rounded-2xl border bg-background md:shadow-xl"
        ref={container}
      >
        <Meteors number={60} />
        {/* Scroll animations */}
        <>
          <div
            className="h-20 w-4 bg-gradient-to-r from-indigo-700 to-indigo-100 absolute bottom-6 left-10 rounded-full box-c"
            data-speed="2.5"
          />
          <div
            className="h-14 w-14 bg-gradient-to-r from-indigo-700 to-indigo-100 absolute top-6 right-20 rounded-full box-c"
            data-speed="1.5"
          />
          <div
            className="h-4 w-4 bg-gradient-to-r from-indigo-700 to-indigo-100 absolute left-[20%] top-40 rounded-full box-c"
            data-speed="1.5"
          />
          <div
            className="h-10 w-4 bg-gradient-to-r from-indigo-400 to-indigo-900 absolute bottom-0 right-10 rounded-full box-c"
            data-speed="1.5"
          />
          <div
            className="h-16 w-3 bg-gradient-to-r from-indigo-400 to-indigo-900 absolute bottom-[20%] right-[20%] rounded-full box-c"
            data-speed="3.1"
          />
          <div
            className="h-6 w-2 bg-gradient-to-r from-indigo-400 to-indigo-900 absolute bottom-[50%] right-[50%] rounded-full box-c"
            data-speed="3.5"
          />
          <div
            className="h-2 w-2 bg-gradient-to-r from-blue-800 to-indigo-900 absolute bottom-[30%] right-[30%] rounded-full box-c"
            data-speed="3.5"
          />
          <div
            className="h-5 w-2 bg-gradient-to-r from-blue-800 to-indigo-900 absolute bottom-[30%] left-[20%] rounded-full box-c"
            data-speed="3.0"
          />
        </>
        <div
          className="absolute inset-x-0 -top-40 -z-2 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
          data-speed="2.5"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="relative flex justify-center items-center gap-10 min-h-[80vh]">
          <div className="w-[100%] h-full w-full lg:w-[60%] text-center flex flex-col justify-center items-center gap-4 ">
            <h1
              data-speed="1"
              className="font-poppins scroll-m-20 text-5xl lg:text-6xl font-extrabold w-full leading-10"
            >
              Crafting{" "}
              <span className="text-blue-700 scroll-m-20 text-5xl font-extrabold tracking-tight lg:text-6xl drop-shadow-xl">
                exceptional websites
              </span>{" "}
              tailored to elevate your business.
            </h1>
            <p
              data-speed="1"
              className="mt-4 text-xl tracking-normal mt-8 font-[100]"
            >
              We are a leading web development company dedicated to crafting
              stunning websites tailored to your business needs.
            </p>
            <Button
              data-speed="1"
              className="flex items-center justify-center gap-2 px-10 py-6 mt-4"
              onClick={() => {
                openModal();
              }}
            >
              <p>Get Started</p>
              <ArrowRight size={18} />
            </Button>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
          data-speed="2.5"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
