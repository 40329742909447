export const ABOUT = "About";
export const SERVICES = "Services";
export const PROJECTS = "Projects";

export const CONTACT_NUMBER = "7987522835";
export const EMAIL = "f5innovationspvt@gmail.com";
export const FACEBOOK = "";
export const INSTAGRAM = "https://www.instagram.com/f5__innovations/";
export const TWITTER = "";
export const YOUTUBE = "";
export const LINKED_IN = "";
