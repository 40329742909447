import React from "react";
import HeroSection from "../components/Hero/HeroSection";
import About from "../components/About/About";
import Services from "../components/Services/Services";
import Portfolio from "../components/Portfolio/Portfolio";
import { ABOUT, PROJECTS, SERVICES } from "../Common/strings";

const HomePage = () => {
  return (
    <>
      <div id="hero-section-scroll-ref">
        <HeroSection />
      </div>
      <div id={`${ABOUT.toLowerCase()}-section-scroll-ref`}>
        <About />
      </div>
      <div id={`${SERVICES.toLowerCase()}-section-scroll-ref`}>
        <Services />
      </div>
      {/* <div id={`${PROJECTS.toLowerCase()}-section-scroll-ref`}>
        <Portfolio />
      </div> */}
    </>
  );
};

export default HomePage;
