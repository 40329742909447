import React from "react";

export const Logo = ({ size = 46, textStyle = "text-xl", className }) => {
  return (
    <div className={(className = "flex items-center")}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        version="1"
        viewBox="0 0 399 407"
      >
        <path
          d="M1175 3405l-780-454-3-423c-2-233 0-648 4-923l7-500 788-453c718-413 791-452 811-441 75 39 1545 893 1554 902 8 9 10 257 6 929l-5 916-783 451c-431 248-792 451-801 450-10-1-369-205-798-454zm1550-97l735-421V1182l-735-426c-404-234-739-426-743-426-5 0-339 190-743 423l-734 422-2 855-3 855 732 423c403 232 738 422 745 422 8 0 344-190 748-422z"
          transform="matrix(.1 0 0 -.1 0 407)"
          fill="white"
        ></path>
        <path
          d="M1450 2460v-730h190v660h510v150h-510v490h631l-3 78-3 77-407 3-408 2v-730z"
          transform="matrix(.1 0 0 -.1 0 407)"
          fill="white"
        ></path>
        <path
          d="M1710 1825v-425h188l12 30c19 47 86 109 148 138 50 24 69 27 152 26 147-2 224-39 280-136 55-97 62-246 18-368-29-78-107-153-184-176-86-25-214-16-287 20-69 33-130 101-152 167l-17 49h-186l9-42c18-81 76-183 135-238 104-94 207-130 376-130 221 0 373 82 453 243 110 224 75 509-80 656-89 85-192 121-339 121-122-1-222-35-304-106l-42-36 2 234 3 233 363 3 362 2v160h-910v-425z"
          transform="matrix(.1 0 0 -.1 0 407)"
          fill="white"
        ></path>
      </svg>
      <text className={textStyle}>Innovations</text>
    </div>
  );
};

export default Logo;
