import React, { createContext, useContext, useState } from "react";

const ContactUsContext = createContext();

export const ContactUsProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  const value = {
    open,
    openModal,
    closeModal,
  };
  return (
    <ContactUsContext.Provider value={value}>
      {children}
    </ContactUsContext.Provider>
  );
};

// Custom hook to consume the loading context
export const useContactUsContext = () => {
  const context = useContext(ContactUsContext);
  if (!context) {
    throw new Error("useLoading must be used within a ContactUsContext");
  }
  return context;
};
