import React, { useState } from "react";
import { NavigationMenuLink } from "../ui/navigation-menu";
import { cn } from "../../lib/utils";
import { Link } from "react-router-dom";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTrigger,
} from "../ui/sheet";
import { ArrowRight } from "lucide-react";
import { Button } from "../ui/button";
import { navigationData } from "../../Common/data";
import Logo from "../Logo";
import { useContactUsContext } from "src/Context/ContactUsContext";

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { openModal } = useContactUsContext();
  return (
    <header className="sticky inset-x-0 top-0 z-50 bg-white-400 bg-clip-padding backdrop-filter backdrop-blur-lg bg-opacity-100">
      <nav
        className="flex items-center justify-between p-2 lg:px-8 border-b"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <Logo size={40} />
          </Link>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigationData.map((item) => (
            <Button
              variant="link"
              key={item.name}
              className="text-sm font-semibold leading-6 text-gray-200"
              onClick={() => {
                const element = document.getElementById(
                  `${item?.name?.toLowerCase()}-section-scroll-ref`
                );
                element.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              {item.name}
            </Button>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <Button
            variant="outline"
            className={"flex items-center justify-center gap-4"}
            onClick={openModal}
          >
            <text>Let's talk</text>
            <ArrowRight size={18} />
          </Button>
        </div>
        <Sheet>
          <SheetTrigger
            className="lg:hidden ml-4 border p-2 px-4 rounded-full"
            onClick={() => setMobileMenuOpen(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              height={"24"}
              width={"24"}
            >
              <path d="M18 18V20H6V18H18ZM21 11V13H3V11H21ZM18 4V6H6V4H18Z"></path>
            </svg>
          </SheetTrigger>
          <SheetContent>
            <SheetHeader>
              <SheetDescription>
                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-gray-700">
                    <div className="py-6">
                      <Button
                        // variant="outline"
                        className={
                          "flex items-center justify-center gap-4 w-full"
                        }
                        onClick={openModal}
                      >
                        <text className="text-2xl font-extrabold tracking-tight">
                          Let's talk
                        </text>
                        <ArrowRight size={24} />
                      </Button>
                    </div>
                    <div className="space-y-2 py-6 flex flex-col gap-2">
                      {navigationData.map((item) => (
                        <Button
                          variant="ghost"
                          key={item.name}
                          className="border p-6 rounded-md font-poppins text-xl lg:text-4xl font-extrabold tracking-tight w-full"
                          onClick={() => {
                            const element = document.getElementById(
                              `${item?.name?.toLowerCase()}-section-scroll-ref`
                            );
                            element.scrollIntoView({
                              behavior: "smooth",
                            });
                            setMobileMenuOpen(false);
                          }}
                        >
                          {item.name}
                        </Button>
                      ))}
                    </div>
                  </div>
                </div>
              </SheetDescription>
            </SheetHeader>
          </SheetContent>
        </Sheet>
      </nav>
    </header>
  );
};
const ListItem = React.forwardRef(
  ({ className, title, children, ...props }, ref) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <Link
            ref={ref}
            className={cn(
              "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
              className
            )}
            to={props.src}
            {...props}
          >
            <div className="text-sm font-medium leading-none">{title}</div>
            <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
              {children}
            </p>
          </Link>
        </NavigationMenuLink>
      </li>
    );
  }
);
ListItem.displayName = "ListItem";
export default Header;
