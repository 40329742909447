import React, { useRef, useState } from "react";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { CONTACT_NUMBER, EMAIL } from "src/Common/strings";
import { useToast } from "../ui/use-toast";
import axios from "axios";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useContactUsContext } from "src/Context/ContactUsContext";

const ContactUs = () => {
  const { toast } = useToast();

  const contactModalRef = useRef();
  const { open } = useContactUsContext();

  useGSAP(
    () => {
      if (open) {
        // Animation for opening the menu
        gsap.fromTo(
          ".form-inputs-button",
          {
            x: 100, // Start from the left (-100%)
            opacity: 0,
          },
          {
            x: 0, // Move to the center (0%)
            opacity: 1,
            duration: 0.5,
            ease: "power2.inOut",
            stagger: 0.2, // Stagger the animations
          }
        );
        gsap.fromTo(
          ".form-text",
          {
            x: -100, // Start from the left (-100%)
            opacity: 0,
          },
          {
            x: 0, // Move to the center (0%)
            opacity: 1,
            duration: 0.5,
            ease: "power2.inOut",
            stagger: 0.4, // Stagger the animations
          }
        );
      }
    },
    { scope: contactModalRef, dependencies: [open] }
  );

  const [stateManager, setStateManager] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });

  const [formLoading, setFormLoading] = useState(false);

  const form = useRef();

  const updateStateManager = (key, value) => {
    let keys = Object.keys(stateManager);
    if (keys.includes(key)) {
      if (errors[key]) setErrors((prev) => ({ ...prev, [key]: "" }));
      setStateManager((prev) => ({ ...prev, [key]: value }));
    } else {
      throw new Error("The key you are trying to add is not available");
    }
  };

  const updateErrors = (key, value) => {
    let keys = Object.keys(errors);
    if (keys.includes(key)) {
      setErrors((prev) => ({ ...prev, [key]: value }));
    } else {
      throw new Error("The key you are trying to add is not available");
    }
  };

  function isValidEmail(email) {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  }

  function isValidPhoneNumber(phoneNumber) {
    const pattern = /^[0-9][0-9]{9}$/;
    return pattern.test(phoneNumber);
  }

  const showErrorToast = (title, description) => {
    toast({
      variant: "destructive",
      title: title || "Uh oh! Something went wrong.",
      description: description || "There was a problem with your request.",
    });
  };

  const sendEmail = async (e) => {
    setFormLoading(true);
    const data = {
      service_id: process.env.REACT_APP_SERVICE_ID,
      template_id: process.env.REACT_APP_TEMPLATE_ID,
      user_id: process.env.REACT_APP_PUBLIC_KEY,
      template_params: {
        from_name: stateManager.name,
        from_email: stateManager.email,
        to_name: "F5 Innovations",
        message: stateManager.description,
        from_phone: stateManager.phone,
      },
    };

    await axios
      .post("https://api.emailjs.com/api/v1.0/email/send", data)
      .then((res) => {
        let keys = Object.keys(stateManager);
        keys.forEach((element) => {
          setStateManager((prev) => ({ ...prev, [element]: "" }));
        });
        toast({
          title: "Form Submitted Successfully.",
          description: "Our team will contact you shortly",
        });
        setFormLoading(false);
      })
      .catch((err) => {
        setFormLoading(false);
        showErrorToast();
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let stateKeys = Object.keys(stateManager);
    let isError = false;
    for (let i = 0; i < stateKeys.length; i++) {
      let key = stateKeys[i];
      if (!stateManager[key]) {
        updateErrors(key, `${key} cannot be empty!`);
        showErrorToast(`${key} cannot be empty!`);
        isError = true;
      } else {
        if (key === "name") {
          if (stateManager[key].trim().length < 4) {
            updateErrors(key, `${key} must be minimum 4 characters`);
            showErrorToast(`${key} must be minimum 4 characters`);
            isError = true;
            return;
          } else {
            updateErrors(key, "");
          }
        } else if (key === "email") {
          if (!isValidEmail(stateManager[key])) {
            updateErrors(key, `Enter a correct ${key}`);
            showErrorToast(`Enter a correct ${key}`);
            isError = true;
            return;
          } else {
            updateErrors(key, "");
          }
        } else if (key === "phone") {
          if (!isValidPhoneNumber(stateManager[key])) {
            updateErrors(key, `Enter a correct ${key} number`);
            showErrorToast(`Enter a correct ${key} number`);
            isError = true;
            return;
          } else {
            updateErrors(key, "");
          }
        } else if (key === "description") {
          updateErrors(key, "");
        }
      }
    }
    if (isError) return;

    console.log("DOWNNNN");
    if (!isError) sendEmail(e);
  };

  return (
    <section
      className="relative z-10 overflow-hidden py-10 lg:py-[40px]"
      ref={contactModalRef}
    >
      <div className="container p-0">
        <div className="-mx-4 flex flex-wrap lg:justify-between">
          <div className="w-full px-4 lg:w-1/3 xl:w-6/12">
            <div className="mb-12 max-w-[570px] lg:mb-0">
              <span className="form-text mb-4 block text-base font-semibold text-primary">
                Contact Us
              </span>
              <h2 className="form-text mb-6 text-[32px] font-extrabold uppercase sm:text-[40px] lg:text-[36px] xl:text-[40px]">
                GET IN TOUCH WITH US
              </h2>
              {/* <p className="mb-9 text-base leading-relaxed text-body-color dark:text-dark-6">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eius tempor incididunt ut labore e dolore magna aliqua. Ut enim
                adiqua minim veniam quis nostrud exercitation ullamco
              </p> */}

              <div className="form-text mb-8 flex w-full max-w-[370px]">
                <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-primary sm:h-[70px] sm:max-w-[70px]">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_941_17577)">
                      <path
                        d="M24.3 31.1499C22.95 31.1499 21.4 30.7999 19.7 30.1499C16.3 28.7999 12.55 26.1999 9.19997 22.8499C5.84997 19.4999 3.24997 15.7499 1.89997 12.2999C0.39997 8.59994 0.54997 5.54994 2.29997 3.84994C2.34997 3.79994 2.44997 3.74994 2.49997 3.69994L6.69997 1.19994C7.74997 0.599942 9.09997 0.899942 9.79997 1.89994L12.75 6.29994C13.45 7.34994 13.15 8.74994 12.15 9.44994L10.35 10.6999C11.65 12.7999 15.35 17.9499 21.25 21.6499L22.35 20.0499C23.2 18.8499 24.55 18.4999 25.65 19.2499L30.05 22.1999C31.05 22.8999 31.35 24.2499 30.75 25.2999L28.25 29.4999C28.2 29.5999 28.15 29.6499 28.1 29.6999C27.2 30.6499 25.9 31.1499 24.3 31.1499ZM3.79997 5.54994C2.84997 6.59994 2.89997 8.74994 3.99997 11.4999C5.24997 14.6499 7.64997 18.0999 10.8 21.2499C13.9 24.3499 17.4 26.7499 20.5 27.9999C23.2 29.0999 25.35 29.1499 26.45 28.1999L28.85 24.0999C28.85 24.0499 28.85 24.0499 28.85 23.9999L24.45 21.0499C24.45 21.0499 24.35 21.0999 24.25 21.2499L23.15 22.8499C22.45 23.8499 21.1 24.1499 20.1 23.4999C13.8 19.5999 9.89997 14.1499 8.49997 11.9499C7.84997 10.8999 8.09997 9.54994 9.09997 8.84994L10.9 7.59994V7.54994L7.94997 3.14994C7.94997 3.09994 7.89997 3.09994 7.84997 3.14994L3.79997 5.54994Z"
                        fill="currentColor"
                      />
                      <path
                        d="M29.3 14.25C28.7 14.25 28.25 13.8 28.2 13.2C27.8 8.15003 23.65 4.10003 18.55 3.75003C17.95 3.70003 17.45 3.20003 17.5 2.55003C17.55 1.95003 18.05 1.45003 18.7 1.50003C24.9 1.90003 29.95 6.80003 30.45 13C30.5 13.6 30.05 14.15 29.4 14.2C29.4 14.25 29.35 14.25 29.3 14.25Z"
                        fill="currentColor"
                      />
                      <path
                        d="M24.35 14.7002C23.8 14.7002 23.3 14.3002 23.25 13.7002C22.95 11.0002 20.85 8.90018 18.15 8.55018C17.55 8.50018 17.1 7.90018 17.15 7.30018C17.2 6.70018 17.8 6.25018 18.4 6.30018C22.15 6.75018 25.05 9.65018 25.5 13.4002C25.55 14.0002 25.15 14.5502 24.5 14.6502C24.4 14.7002 24.35 14.7002 24.35 14.7002Z"
                        fill="currentColor"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_941_17577">
                        <rect width="32" height="32" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="w-full">
                  <h4 className="mb-1 text-xl font-bold">Phone Number</h4>
                  <p className="text-base text-body-color dark:text-dark-6">
                    <a href={`tel:${EMAIL}`} target="_blank">
                      {CONTACT_NUMBER}
                    </a>
                  </p>
                </div>
              </div>

              <div className="form-text mb-8 flex w-full max-w-[370px]">
                <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-primary sm:h-[70px] sm:max-w-[70px]">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28 4.7998H3.99998C2.29998 4.7998 0.849976 6.1998 0.849976 7.9498V24.1498C0.849976 25.8498 2.24998 27.2998 3.99998 27.2998H28C29.7 27.2998 31.15 25.8998 31.15 24.1498V7.8998C31.15 6.1998 29.7 4.7998 28 4.7998ZM28 7.0498C28.05 7.0498 28.1 7.0498 28.15 7.0498L16 14.8498L3.84998 7.0498C3.89998 7.0498 3.94998 7.0498 3.99998 7.0498H28ZM28 24.9498H3.99998C3.49998 24.9498 3.09998 24.5498 3.09998 24.0498V9.2498L14.8 16.7498C15.15 16.9998 15.55 17.0998 15.95 17.0998C16.35 17.0998 16.75 16.9998 17.1 16.7498L28.8 9.2498V24.0998C28.9 24.5998 28.5 24.9498 28 24.9498Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                <div className="w-full">
                  <h4 className="mb-1 text-xl font-bold">Email Address</h4>
                  <p className="text-base text-body-color dark:text-dark-6">
                    <a href={`mailto:${EMAIL}`} target="_blank">
                      {EMAIL}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full m-2 px-2 lg:w-1/2 xl:w-5/12">
            <div className="relative border flex flex-col gap-2 rounded-lg py-10 sm:p-6 lg:py-12 px-4 shadow-lg ">
              <form
                className={`flex flex-col gap-2 ${
                  formLoading && "animate-pulse"
                }`}
                ref={form}
                onSubmit={handleSubmit}
              >
                <Input
                  name="from_name"
                  type="text"
                  placeholder="Your Name"
                  value={stateManager.name}
                  defaultValue={stateManager.name}
                  className={`${errors.name && "border-red-600"} ${
                    formLoading && "bg-gray-900"
                  } form-inputs-button`}
                  onChange={(e) => {
                    updateStateManager("name", e.target.value);
                  }}
                  disabled={formLoading}
                />
                <div className="h-6">
                  {errors.name && (
                    <p className="text-red-600 text-xs">{errors.name}</p>
                  )}
                </div>
                <Input
                  from="from-email"
                  type="text"
                  placeholder="Your Email"
                  value={stateManager.email}
                  defaultValue={stateManager.email}
                  className={`${errors.email && "border-red-600"} ${
                    formLoading && "bg-gray-900"
                  } form-inputs-button`}
                  onChange={(e) => {
                    updateStateManager("email", e.target.value);
                  }}
                  disabled={formLoading}
                />
                <div className="h-6">
                  {errors.email && (
                    <p className="text-red-600 text-xs">{errors.email}</p>
                  )}
                </div>

                <Input
                  name="from_phone"
                  type="text"
                  placeholder="Your Phone"
                  value={stateManager.phone}
                  defaultValue={stateManager.phone}
                  className={`${errors.phone && "border-red-600"} ${
                    formLoading && "bg-gray-900"
                  } form-inputs-button`}
                  onChange={(e) => {
                    updateStateManager("phone", e.target.value);
                  }}
                  disabled={formLoading}
                />
                <div className="h-6">
                  {errors.phone && (
                    <p className="text-red-600 text-xs">{errors.phone}</p>
                  )}
                </div>
                <textarea
                  name="phone_message"
                  row="6"
                  placeholder="Your Message"
                  value={stateManager.description}
                  defaultValue={stateManager.description}
                  className={`${errors.description && "border-red-600"} ${
                    formLoading && "bg-gray-900"
                  } w-full resize-none rounded border bg-transparent border-stroke px-[14px] py-6 outline-none focus:border-primary dark:border-dark-3 dark:text-dark-6 form-inputs-button`}
                  onChange={(e) => {
                    updateStateManager("description", e.target.value);
                  }}
                  disabled={formLoading}
                />
                <div className="h-6">
                  {errors.description && (
                    <p className="text-red-600 text-xs">{errors.description}</p>
                  )}
                </div>
                <div>
                  <Button
                    type="button"
                    onClick={handleSubmit}
                    className="w-full form-inputs-button"
                    disabled={formLoading}
                  >
                    {formLoading ? "Sending Details..." : "Send Message"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
