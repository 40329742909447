import { useLocation } from "react-router-dom";
import { useLoadingContext } from "../Context/LoadingContext";
import { useEffect } from "react";

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  const { open } = useLoadingContext();

  useEffect(() => {
    if (!open) window.scrollTo(0, 0);
  }, [pathname, open]);

  return null;
};
