import React from "react";
import { Button } from "../ui/button";
import { useContactUsContext } from "src/Context/ContactUsContext";

const About = () => {
  const { openModal } = useContactUsContext();
  return (
    <div>
      <section className="overflow-hidden pt-10">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row items-center justify-between -mx-4">
            <div className="w-full px-4 lg:w-6/12">
              <div className="flex items-center -mx-3 sm:-mx-4">
                <div className="w-full px-3 sm:px-4 xl:w-1/2">
                  <div className="py-3 sm:py-4">
                    <img
                      src="https://i.ibb.co/gFb3ns6/image-1.jpg"
                      alt=""
                      className="w-full rounded-2xl"
                    />
                  </div>
                  <div className="py-3 sm:py-4">
                    <img
                      src="https://i.ibb.co/rfHFq15/image-2.jpg"
                      alt=""
                      className="w-full rounded-2xl"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:px-4 xl:w-1/2">
                  <div className="relative z-10 my-4">
                    <img
                      src="https://i.ibb.co/9y7nYCD/image-3.jpg"
                      alt=""
                      className="w-full rounded-2xl"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
              <div className="mt-10 lg:mt-0">
                <span className="block mb-4 text-lg font-semibold text-primary">
                  About Us
                </span>
                <h2 className="mb-5 text-3xl font-bold text-dark dark:text-white sm:text-[40px]/[48px]">
                  Your vision, our expertise. Your success, our priority.
                </h2>
                <p className="mb-5 text-base text-body-color dark:text-dark-6">
                  At F5 Innovations, we're passionate about building exceptional
                  websites that help businesses thrive in the digital world.
                </p>
                <p className="mb-8 text-base text-body-color dark:text-dark-6">
                  As a software development company, our mission is to deliver
                  innovative and high-quality solutions that address the needs
                  and challenges of our clients while positively impacting
                  society. We are committed to leveraging cutting-edge
                  technologies and best practices to develop software that is
                  efficient, reliable, and scalable.
                </p>

                <Button
                  onClick={() => {
                    openModal();
                  }}
                >
                  Get Started
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="overflow-hidden pt-10">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row-reverse items-center justify-between -mx-4">
            <div className="w-full px-4 lg:w-6/12">
              <div className="flex items-center -mx-3 sm:-mx-4">
                <div className="w-full px-3 sm:px-4 xl:w-1/2">
                  <div className="py-3 sm:py-4">
                    <img
                      src="https://img.freepik.com/free-photo/modern-equipped-computer-lab_23-2149241261.jpg?t=st=1714423237~exp=1714426837~hmac=d1c2de1582947f74c2e30f2a3b2a027d51ade9dd04a49f047094c4542f3b61d6&w=996"
                      alt=""
                      className="w-full rounded-2xl"
                    />
                  </div>
                  <div className="py-3 sm:py-4">
                    <img
                      src="https://img.freepik.com/free-photo/modern-equipped-computer-lab_23-2149241221.jpg?t=st=1714423328~exp=1714426928~hmac=4563d39bba36d0496791ec2c013619095a25bc8fbb872ae85bfbda1d67352303&w=996"
                      alt=""
                      className="w-full rounded-2xl"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:px-4 xl:w-1/2">
                  <div className="relative z-10 my-4">
                    <img
                      src="https://img.freepik.com/free-photo/medium-shot-people-with-laptop_23-2149005851.jpg?t=st=1714423585~exp=1714427185~hmac=bab2300a1814ac213230ca5ddb9872955824d65beb141f0259ef55f9922b6704&w=360"
                      alt=""
                      className="w-full rounded-2xl"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
              <div className="mt-10 lg:mt-0">
                <span className="block mb-4 text-lg font-semibold text-primary">
                  Our Mission
                </span>
                <h2 className="mb-5 text-3xl font-bold text-dark dark:text-white sm:text-[40px]/[48px]">
                  Client Satisfaction
                </h2>
                <p className="mb-5 text-base text-body-color dark:text-dark-6">
                  We prioritize understanding our clients' requirements and
                  strive to exceed their expectations by delivering solutions
                  that meet their needs effectively and efficiently.
                </p>
                <p className="mb-8 text-base text-body-color dark:text-dark-6">
                  We maintain rigorous quality standards throughout the software
                  development lifecycle to ensure that our products are
                  reliable, robust, and bug-free. Quality assurance is integral
                  to everything we do.
                </p>

                <Button
                  onClick={() => {
                    openModal();
                  }}
                >
                  Get Started
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="overflow-hidden pt-10">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row items-center justify-between -mx-4">
            <div className="w-full px-4 lg:w-6/12">
              <div className="flex items-center -mx-3 sm:-mx-4">
                <div className="w-full px-3 sm:px-4 xl:w-1/2">
                  <div className="py-3 sm:py-4">
                    <img
                      src="https://img.freepik.com/free-photo/modern-equipped-computer-lab_23-2149241224.jpg?t=st=1714423738~exp=1714427338~hmac=e1a7587d712f705a054088abb2c7827e567370052258694d5801ad422bf337e1&w=996"
                      alt=""
                      className="w-full rounded-2xl"
                    />
                  </div>
                  <div className="py-3 sm:py-4">
                    <img
                      src="https://img.freepik.com/free-photo/person-front-computer-working-html_23-2150040428.jpg?t=st=1714423977~exp=1714427577~hmac=dc156fa08ce9405a395c9c54c55b46297e0d0b9cce21556454bd5f7a8606ed5f&w=996"
                      alt=""
                      className="w-full rounded-2xl"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:px-4 xl:w-1/2">
                  <div className="relative z-10 my-4">
                    <img
                      src="https://img.freepik.com/free-photo/top-view-unrecognizable-hacker-performing-cyberattack-night_1098-18706.jpg?t=st=1714423827~exp=1714427427~hmac=6257035a4a4e86a17b0abe657ade2b049df2e93a65e4f42c5ea4fad14d982fed&w=360"
                      alt=""
                      className="w-full rounded-2xl"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
              <div className="mt-10 lg:mt-0">
                <span className="block mb-4 text-lg font-semibold text-primary">
                  Why Choose Us
                </span>
                <p className="mb-8 text-base text-body-color dark:text-dark-6">
                  We recognize our responsibility to contribute positively to
                  society. Whether through developing software that improves
                  access to essential services, promotes sustainability, or
                  enhances education and healthcare, we aim to make a meaningful
                  difference in people's lives.
                </p>
                <p className="mb-8 text-base text-body-color dark:text-dark-6">
                  By aligning our efforts with these core principles, we strive
                  to be a trusted partner for our clients, a leading innovator
                  in the software industry, and a force for positive change in
                  the world.
                </p>
                <Button
                  onClick={() => {
                    openModal();
                  }}
                >
                  Get Started
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
