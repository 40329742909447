import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { LoadingProvider } from "./Context/LoadingContext";
import { ContactUsProvider } from "./Context/ContactUsContext";
import { Toaster } from "./components/ui/toaster";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LoadingProvider>
        <ContactUsProvider>
          <>
            <App />
            <Toaster />
          </>
        </ContactUsProvider>
      </LoadingProvider>
    </BrowserRouter>
  </React.StrictMode>
);
