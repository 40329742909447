import React from "react";
import { Dialog, DialogContent } from "../ui/dialog";
import { Progress } from "../ui/progress";
import { useLoadingContext } from "src/Context/LoadingContext";
import Meteors from "../Meteors/Meteors";

const LoadingModal = () => {
  const { open, progress } = useLoadingContext();
  return (
    <Dialog open={open}>
      <DialogContent
        hideDefaultClose
        className="h-full lg:h-full min-w-screen max-w-screen w-full border-0 border-gray-900  rounded-lg lg:rounded-sm"
      >
        <div className="flex flex-col items-center justify-center gap-4 w-[100%] lg:w-[50%] m-auto rounded-2xl lg:rounded-full h-[100%] lg:h-[50%] px-4 lg:px-20 py-4 border">
          <Meteors number={30} />
          <h1 className="font-extrabold tracking-tight text-[100px] lg:text-[100px] text-center">
            {`${progress}%`}
          </h1>
          <Progress value={progress} className="h-10" />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingModal;
